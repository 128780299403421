import React, { Component } from 'react'
import "./form.css"


class ContactForm extends Component {
state={
    name: '',
    email:'',
    mobile:'',
    subject:'',
    message:'',
    emailStatus:''
}

labels={
    nameId: 'nameId',
    emailId: 'emailId',
    mobileId: 'mobileId',
    subjectId: 'subjectId',
    messageId: 'messageId'
}

hendleChange = input => e => {
    this.setState({
        [input]: e.target.value
    });
}

submitForm = (e) => {
    const {
        name,
        email,
        mobile,
        subject,
        message
    } = this.state;

    // console.log(this.state);
    //xmlHTTPRequest
    var xhr = new XMLHttpRequest();
    
    //get callback when srw resp
    xhr.addEventListener('load', () =>{
        //upadate res state email status
            this.setState({
              emailStatus: xhr.responseText,
            })
    })

    xhr.open('GET', 'http://mailer.komfortum.pl/index.php?sendto='+email+'&name='+name+'&mobile='+mobile+'&subject='+subject+'&message='+message);
    xhr.send();

    this.setState({
      name: "",
      email: "",
      message: "",
      mobile:"",
      subject: "",
      message: "",
    })
 
    e.preventDefault();
}

render(){
    const{
        name,
        email,
        mobile,
        subject,
        message,
        emailStatus,
    } = this.state;

    const{
        nameId,
        emailId,
        mobileId,
        subjectId,
        messageId,
    } = this.labels;

    return(
        <div className="formBlock">
            <p style={{backgroundColor:'#005B25', color:'white'}}> {emailStatus ? emailStatus : null} </p>
            <form style={{display:'flex', flexDirection:'column', maxWidth: '765px', margin:'auto'}} onSubmit={this.submitForm}>
                <label htmlFor={nameId}>imie i nazwisko</label>
                 <input required id={nameId} type="text" value={name} onChange={this.hendleChange('name')} name="name" placeholder="Imię i nazwisko*" />
                <label  htmlFor={emailId}>Adres email</label>
                 <input required id={emailId} type="email" value={email} onChange={this.hendleChange('email')} name="email" placeholder="Adres email*" />
                <label  htmlFor={mobileId}>Nr.telefonu</label>
                 <input required id={mobileId} type="text" minLength='9' value={mobile} onChange={this.hendleChange('mobile')} name="mobile" placeholder="Nr. telefonu*"/>
                <label  htmlFor={subjectId}>Temat</label>
                 <input required id={subjectId} type="text" minLength="5" maxLength='100' value={subject} onChange={this.hendleChange('subject')} name="subject" placeholder="Temat*" />
                <label  htmlFor={messageId}>Treść wiadomości</label>
                <textarea id={messageId} rows="5" value={message} minLength="5" onChange={this.hendleChange('message')} name="message" placeholder="Treść wiadomości*"></textarea>
                 <input required id="checkbox" type="checkbox" name="zgoda"/>
                <label for="checkbox">Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu nawiązania kontaktu z wykorzystaniem niniejszego formularza kontaktowego i w celu otrzymania odpowiedzi / informacji w sprawach, o które się zwracam.</label>
                 <input id="sub" style={{width: 'fit-content', margin:'auto'}} type="submit" className="komf-btn" value="wyślij" />
            </form>   
        </div>
    )
}

}
export default ContactForm;
