import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import ContactForm from "../components/contactForm"
import CookieConsent from 'react-cookie-consent'
import { Media } from "../media/media"


import Buildings from "../images/buildings.png"
import TeamFoto from "../images/team.png"
import Icon1 from "../images/1.png"
import Icon2 from "../images/2.png"
import Icon3 from "../images/3.png"
import Icon4 from "../images/4.png"
import Icon5 from "../images/5.png"
import Icon6 from "../images/6.png"
import Check from "../images/checkpoint.svg"
import Arrow from "../images/arrow.svg"



const Hdr1 = styled.h1`
    font-size:3em;
    text-transform: uppercase;
    display: inline-block;
    color: ${props => (props.color === "green" ? "#005B25" : "black")};
    border-bottom: solid #C59529 0.1em; 
    span{
        font-family: 'Afta Serif';
    }
`

const Hdr2 = styled.h2`
    font-size:2.3em;
    margin-top:0.5em;
    text-transform: uppercase;
    display: inline-block;
    color: "black";
    }
`

const Head = styled.section`
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  margin: 3em auto;
  position: relative;
  display: flex;
  flex-direction: row;

  .headclaim {
    z-index: 1;
    margin: auto -2em auto 1em;
    padding: 5em 3em 4em 2em;
    text-align: center;
    border: solid #c59529 0.2em;
    max-width: 50%;
    h1 {
      display: inline-block;
      border-bottom: solid #c59529 0.1em;
      font-weight: 300;
      font-family: "roboto", sans-serif;
      span {
        font-family: "Afta Serif";
        text-transform: uppercase;
      }
    }
    h2 {
      font-size: 1.8em;
      display: inline-block;
      font-weight: 300;
      font-family: "roboto", sans-serif;
    }
  }
  .headimg {
    margin-top: 3em;
    z-index: 0;
  }

  ${Media.sm`
        flex-direction: column;
        .headclaim {
            max-width: 80%;
            margin: auto;
            h1{
                font-size:2.5em;            }
        }
    `}
`

const HeadImg =styled.img`
    height: auto;
    max-height:100%;
    margin: 0;
`

const About = styled.section`
  margin: auto;
  padding-top: 5em;
  display: block;
  text-align: center;
  ${Media.sm`
        padding-top: 8em;
    `}
`

const AboutClaim = styled.div`
    max-width: ${props => (props.width === "max" ? "100vw" : "1024px")};
    margin:auto;
    display: block;
    text-align: center;
`

const Team = styled.div`
  position: relative;
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  background-color: ${props =>
    props.bg === "gold" ? "rgba(197, 149, 41, 0.27)" : "white"};
  display: block;
  text-align: center;
  margin: 0 auto 9em auto;
  padding-bottom: 30em;
  p {
    padding: 1.5em;
    margin: auto;
    max-width: 1024px;
  }
  img {
    position: absolute;
    max-width: 100%;
    margin: auto;
    right: 0;
    left: 0;
  }
  ${Media.sm`
        img{
            width:100%;
            max-width: 500px;
        }
    `}
`

const WeKnow = styled.section`
  padding: 3em 0;
  text-align: center;
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  margin: auto;
  background-color: ${props =>
    props.bg === "lightgold" ? "rgba(239, 226, 197, 0.21)" : "white"};
  display: block;

  .check {
    width: 70px;
    margin-right: 1em;
    align-self: flex-start;
  }

  ul {
    margin: auto;
    max-width: 1024px;
    display: block;
    text-align: center;
  }

  li {
    margin: 1em auto 1em auto;
    width: fit-content;
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: left;

    .kmf-span {
      color: #c59529;
      font-weight: 400;
    }
  }
  ${Media.sm`
        .check{
            width: 35px;
        }
    `}
`

const Know = styled.span`
    color:black;
    font-size: 1.2em;
    font-family: 'Bebas neue', sans-serif;
`

const Services = styled.section`
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  margin: auto;
  padding-top: 5em;
  text-align: center;
  p {
    max-width: 1440px;
    margin: auto;
  }
  ${Media.sm`
        padding-top: 8em;
    `}
`

const Proceses = styled.div`
    margin: 3em auto; 
    padding: 3em 0; 
    background-color: ${props =>
      props.bg === "lightgold" ? "rgba(239, 226, 197, 0.21)" : "white"};
    div{
        max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
        margin:auto;
    }
    ul{
        margin:0;
        display: flex;
        flex-direction: row;
        li{
            border: solid #C59529 0.2em;
            margin:  1em;
            min-height:100%;
            padding: 2em;
            width: fit-content;
            list-style: none
        }
    }
    ${Media.md`
        ul{
            flex-direction: column;
            li{
              padding: 0.5em;  
              margin: 0.5em;
            }
        }
    `}
    ${Media.sm`
        ul{
            flex-direction: column;
            li{
              padding: 1em;  
              margin: 1em;
            }
        }
    `}
    }
`
const Cowork = styled.section`
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  margin: auto;
  padding-top: 5em;
  display: block;
  text-align: center;
  ol {
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  li {
    margin: 0.8em;
    padding: 1em;
    list-style: none;
    display: block;
    span {
      font-family: "Bebas neue", sans-serif;
      color: #005b25;
      font-size: 1.5em;
    }
  }
  .kfm-disc {
    margin: auto;
    max-width: 80%;
    font-size: 1.1em;
    margin-bottom: 1em;
  }
  hr {
    margin: auto;
    width: 80%;
  }
  ${Media.sm`
        padding-top: 8em;
        ol{
            max-width: 95%;
            justify-content: center;
        }
        li{
            max-width:33%;
            margin:0.3em;
            padding:0;
            img{
                margin: 1em 0; 
                max-width:50px;
            }
            p{
                font-size:1em;
                br{
                    display:none;
                }
            }
        }
        .kfm-disc{
            margin-top:2em;
        } 
    `}
`

const Contact = styled.section`
  max-width: ${props => (props.width === "max" ? "100vw" : "1440px")};
  margin: auto;
  padding-top: 5em;
  display: block;
  text-align: center;
  p {
    margin-bottom: 0.5em;

    b {
      font-family: "Afta Serif";
      text-transform: uppercase;
    }
  }
  form {
    margin-top: 1em;
    label {
      visibility: hidden;
    }
    label[for="checkbox"] {
      visibility: visible;
      font-size: 0.8em;
      line-height: 1em;
      margin: 0 0 1em 2em;
      text-align: left;
    }
    input[id="checkbox"] {
      margin: 10px 10px -15px 10px;
    }
    input[type="submit"] {
      display: inline-block;
      font-family: "Bebas neue", sans-serif;
      font-size: 1.5em;
      color: white;
      background: ${props => (props.bg === "green" ? "#005B25" : "#C59529")};
      border: none;
      padding: 0.5em 1em;
    }
  }
  ${Media.sm`
        padding-top: 8em;
        form{
            label[for=checkbox]{
                margin: 0 0 1em 2.75em;
            }
        }
    `}
`
const KmfButton = styled.button`
    display: inline-block;
    font-family: 'Bebas neue', sans-serif;
    font-size: 1.5em;
    color: white;
    background: ${props => (props.bg === "green" ? "#005B25" : "#C59529")};
    border: none;
    padding: 0.5em 1em;
    a{
        vertical-align:middle;
        display:inline-block;
        font-size: inherit;
        font-family: inherit;
        color:inherit;
    }
    img{
        vertical-align:middle;
        margin:0;
        margin-left: 0.5em;
        height:1.1em;
    }
    `

    const Index = styled.div `
    `

const IndexPage = () => (
    <Layout >
    <Index id="mainpage">
    <SEO title="KOMFORTUM" description="KOMFORTUM Sp. z o.o. Obsługa Wspólnot Mieszkaniowych" />
          <Head>
              <div className="headclaim">
                  <h1 color="green">Jesteśmy <span className="kmf-span">komfortum</span></h1>
                  <h2>Dbamy o Twój komfort zamieszkania</h2>
                  <p>Gwarantujemy wysoką jakość i unikalny standard świadczonych przez nas usług.</p>
              </div>
              <div className="headimg">
                    <HeadImg src={Buildings} alt="to jest obraz bloków" />
              </div>
          </Head>    
          <About id="about" color="green">
              <Hdr1 color="green">o nas</Hdr1>
              <AboutClaim>
                  <p> Nasza praca nie składa się z obowiązków, a z misji. Jej celem jest zapewnienie maksymalnego komfortu życia każdemu mieszkańcowi.<br/><br/>Nieruchomości, którymi opiekujemy się, to poniekąd nasz drugi dom. Doskonale zdajemy sobie sprawę, że ta przestrzeń dla jej mieszkańców jest pewnego rodzaju ostoją. To właśnie w niej powinni móc odpocząć, zregenerować się i nabrać energii do nowych wyzwań. Ważne jest, by czuli się tu bezpiecznie i komfortowo. </p>
              </AboutClaim>
                  <Hdr2>profesjonalny zespół</Hdr2>
              <Team bg="gold" >
                  <p>KOMFORTUM jest tworzone przez doświadczony zespół, który nie tylko dba o jakość i unikalny standard świadczonych przez nas usług, ale przede wszystkim podchodzi po ludzku do codziennych spraw zgłaszanych przez mieszkańców.<br /><br /> Dotychczasowe doświadczenie zespołu KOMFORTUM to zarządzanie kilkunastoma wspólnotami mieszkaniowymi o ponad 200 tys. m2 powierzchni. Doświadczenie o wysoką dbałość techniczną nieruchomości oraz o jej wzrost wartości członkowie zespołu zebrali, zarządzając m.in. takimi nieruchomościami jak: Centrum Nauki Kopernik, PLL LOT, Bank Gospodarstwa Krajowego, PGNiG.</p>
                  <img src={TeamFoto} alt="zdjecie zepołu komfortum"/>
              </Team>
              </About>
              <WeKnow bg="lightgold" width="max">
                  <Hdr2>poznaj nas bliżej</Hdr2>
                  <ul>
                    <li>
                        <img className="check" src={Check} alt="check symbol" />
                        <p><Know>Wiemy, że</Know> mieszkańcy każdego dnia napotykają wiele trudności, które potrafią skutecznie obniżyć jakość życia w ich wymarzonym mieszkaniu. Dlatego <span className="kmf-span">proces zgłaszania każdej niedogodności i usterki skracamy do minimum.</span></p>
                    </li>
                    <li>
                        <img className="check" src={Check} alt="check symbol" />
                        <p><Know>Wiemy, że</Know> jest wiele procedur, które potrafią uprzykrzyć dzień, dlateg <span className="kmf-span"> my upraszczamy cały proces współpracy z nami </span>poprzez odpowiednie narzędzia, które skutecznie podnoszą efektywność naszej pracy świadczonej na rzecz wspólnoty mieszkaniowej.</p>
                    </li>
                    <li>
                        <img className="check" src={Check} alt="check symbol" />
                        <p><Know>Wiemy, że</Know> nic tak nie zepsuje dnia, jak np. nagły brak wody. Dlatego <span className="kmf-span">o wszelkich planowanych naprawach lub przerwach informujemy z odpowiednim wyprzedzeniem.</span> Jeśli natomiast ma miejsce nieoczekiwana awaria, powiadamiamy poprzez sms oraz ogłoszenia na tablicy.</p>
                    </li>
                    <li>
                        <img className="check" src={Check} alt="check symbol" />
                        <p><Know>Wiemy, że</Know> wspólnie z Zarządem Wspólnoty dbamy o Wasz majątek, dlatego uważamy, że każdy mieszkaniec ma prawo wiedzieć, jak wydatkowane są te środki. My jesteśmy transparentni, stąd <span className="kmf-span">cyklicznie przedstawiamy rozliczenie z powierzonego nam budżetu.</span></p>
                    </li>
                    <li>
                        <img  className="check" src={Check} alt="check symbol" />
                        <p><Know>Wiemy, że</Know> właściciele mają pełnię praw decydowania o kwestiach Wspólnoty. Dlatego zebrania, głosowania i inne kluczowe kwestie zapadają na organizowanych przez nas spotkaniach, a osoby, które nie mogły się na nich pojawić, otrzymują do wglądu pełne sprawozdania, a także możliwość oddania głosu korespondencyjnie (mail lub wypełniona karta do głosowania). Przed podjęciem ważnych decyzj <span className="kmf-span"> umożliwiamy wypowiadanie się </span> właścicielom poprzez ankiety.</p>
                    </li>
                  </ul>
              </WeKnow>
        <Services id="services"  width="max" >
            <Hdr1 color="green">nasze usługi</Hdr1>
            <p>Wiemy, że każda Wspólnota ma różne potrzeby, dlatego oferujemy szeroki wachlarz świadczonych usług, z których na etapie rozmów o podjęciu współpracy wspólnie wybieramy te, które stworzą najbardziej komfortowy pakiet dopasowany do jej potrzeb. Ponadto doskonale zdajemy sobie sprawę, że potrzeby z czasem się zmieniają, dlatego w trakcie naszej współpracy możemy również modyfikować ich zakres.</p><br />
            <p>To, co również wyróżnia KOMFORTUM to zdobyte doświadczenie w zarządzaniu nieruchomościami komercyjnymi. Tę wiedzę i praktykę wdrażamy na grunt obsługi Wspólnot Mieszkaniowych.</p>
            <Proceses Proceses bg = "lightgold"  >
                <Hdr2>Zaliczamy do nich procesy związane z:</Hdr2>
                <div>
                    <ul>
                        <li>
                            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <title>4squers</title>
                            <rect fill="transparent" id="canvas_background" height="52" width="52" y="-1" x="-1"/>
                            <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                            <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%"/>
                            </g>
                            </g>
                            <g>
                            <title>L1</title>
                            <rect stroke="#C59529" id="svg_1" height="9.66665" width="10.33332" y="10" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_2" height="9.66665" width="10.33332" y="10" x="30" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_3" height="9.66665" width="10.33332" y="30" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_4" height="9.66665" width="10.33332" y="30" x="30" strokeWidth="1.5" fill="#C59529"/>
                            </g>
                            </svg>
                            <p>Miesięcznym planem działań operacyjnych (stałe czynności jak obchody nieruchomości; weryfikacja faktur kosztowych; serwisy; raportowanie; itp.);</p>
                        </li>
                        <li>
                            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <title>4squers</title>
                            <rect fill="transparent" id="canvas_background" height="52" width="52" y="-1" x="-1"/>
                            <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                            <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%"/>
                            </g>
                            </g>
                            <g>
                            <title>L1</title>
                            <rect stroke="#C59529" id="svg_1" height="9.66665" width="10.33332" y="10" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_2" height="9.66665" width="10.33332" y="10" x="30" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_3" height="9.66665" width="10.33332" y="30" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_4" height="9.66665" width="10.33332" y="30" x="30" strokeWidth="1.5" fill="#C59529"/>
                            </g>
                            </svg>
                            <p>Roczny plan serwisu zapobiegawczego (dotyczy to zaplanowania wszystkich czynności serwisowych dla instalacji i urządzeń zainstalowanych na nieruchomości);</p>
                        </li>
                        <li>
                            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <title>4squers</title>
                            <rect fill="transparent" id="canvas_background" height="52" width="52" y="-1" x="-1"/>
                            <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                            <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%"/>
                            </g>
                            </g>
                            <g>
                            <title>L1</title>
                            <rect stroke="#C59529" id="svg_1" height="9.66665" width="10.33332" y="10" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_2" height="9.66665" width="10.33332" y="10" x="30" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_3" height="9.66665" width="10.33332" y="30" x="10" strokeWidth="1.5" fill="#C59529"/>
                            <rect stroke="#C59529" id="svg_4" height="9.66665" width="10.33332" y="30" x="30" strokeWidth="1.5" fill="#C59529"/>
                            </g>
                            </svg>
                            <p>Pięcioletnie plany gospodarcze bazujące na protokołach przeglądów budowlanych oraz czynności wynikających z Dokumentacji Techniczno-Ruchowej (DTR) poszczególnych urządzeń.</p>
                        </li>
                    </ul>
                    <p>Z doświadczenia wiemy, że takie podejście minimalizuje ryzyko występowania awarii, a co za tym idzie podniesienia komfortu zamieszkiwania w nieruchomości obsługiwanej właśnie w taki sposób.</p>
                </div>    
            </Proceses>
                <Link to="/zakres-uslug"><KmfButton>Dowiedz się wiecej<img src={Arrow} alt="strzałka" /></KmfButton></Link>
        </Services>
        <Cowork id="cowork">
            <Hdr1 color="green" >współpraca</Hdr1>
            <ol>
                <li>
                    <img className="kmf-icon" src={Icon1} alt="ikona "/>
                    <p><span>1.</span> Poznajemy potrzeby wspólnoty</p>
                </li>
                <li>
                    <img className="kmf-icon" src={Icon2} alt="ikona "/>
                    <p><span>2.</span> Definiujemy zakres usług</p>
                </li>
                <li>
                    <img className="kmf-icon" src={Icon3} alt="ikona "/>
                    <p><span>3.</span> Przygotowujemy ofertę.</p>
                </li>
            </ol>
            <ol>
                <li>
                    <img className="kmf-icon" src={Icon4} alt="ikona "/>
                    <p><span>4.</span> Prezentujemy jej zakres</p>
                </li>
                <li>
                    <img className="kmf-icon" src={Icon5} alt="ikona "/>
                    <p><span>5.</span> Ustalamy szczegóły, a także<br /> odpowiadamy na dodatkowe pytania</p>
                </li>
                <li>
                    <img className="kmf-icon" src={Icon6} alt="ikona "/>
                    <p><span>6</span> Podpisujemy umowę</p>
                </li>
            </ol>
            <p className="kfm-disc">Jeśli szukasz zarządcy dla Wspólnoty Mieszkaniowej, który zaopiekuje się Twoją nieruchomością, zapewniając komfort obsługi przy maksymalnym uproszczeniu zasad współpracy, to zapraszamy Cię do kontaktu.</p>
            <hr style={{backgroundColor: '#C59529', marginBottom: 'calc(2.45rem - 1px)',height:'0.2em'}} />
        </Cowork>
          <Contact id="contact">
              <p>Obsługa Wspólnot Mieszkaniowych</p>
              <p><b>KOMFORTUM Sp. z o.o.</b></p>
              <p>Numer KRS 0000843783</p>
              <a href="tel:602823454">kom. 602 823 454</a><br/>
              <a href="tel:226580514">tel. 22 658 05 14</a><br/>
              <a href="mailto:biuro@komfortum.pl">mail: biuro@komfortum.pl</a><br/>
               <ContactForm></ContactForm>
          </Contact>
        </Index>

        <CookieConsent 
                  style={{
                    backgroundColor:'#005B25', 
                    alignItems:'center'
                  }}
                  location="bottom"
                  buttonText="Akceptuje"
                  declineButtonText="Decline"
                  cookieName="gatsby-gdpr-google-analytics"
                  buttonStyle={{
                    background: "#c59529",
                    color: "white",
                    fontFamily: '"Bebas neue",sans-serif'
                  }}
                  >
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez KOMFORTUM sp.z o.o.z siedzibą w Warszawie (ul.Prymasa Augusta Hlonda 10 / 73 02 - 972 Warszawa) w celu skorzystania z usługi e - mail kontaktowy.Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania.Zostałem / am poinformowany / a, że przysługuje mi prawo dostępu do swoich danych, ich modyfikacji oraz usunięcia.Więcej informacji znajduje się w&nbsp;
                  <Link style={{color:'#c59529'}} to='/privacy-policy'>Polityce Prywatności i Cookies.</Link>        
          </CookieConsent>
    </Layout>
)

export default IndexPage
